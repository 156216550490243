<template>
  <el-container style="height: 100%">
    <el-header style="padding: 0" height="auto">
      <el-row :gutter="10">
        <el-col :xs="16" :sm="20">
          <el-input prefix-icon="el-icon-search" v-model="query" placeholder="Search module name" />
        </el-col>

        <el-col :xs="8" :sm="4">
          <el-button
            style="width: 100%"
            type="primary"
            :loading="saving"
            :disabled="!valid"
            @click="reviewChanges"
          >
            Save
          </el-button>
        </el-col>
      </el-row>
    </el-header>

    <el-main style="padding: 10px 0 0 0; overflow-x: hidden">
      <el-tabs
        v-if="isMobileDevice"
        :tab-position="tabPosition"
        v-model="active"
        :stretch="true"
        style="height: 100%"
      >
        <el-tab-pane
          :lazy="true"
          v-for="(config, module_id) in filteredList"
          :key="module_id"
          :name="module_id"
        >
          <div slot="label">
            <span style="line-height: 9px; vertical-align: middle; margin-right: 10px">
              {{ module_id.toUpperCase().replace("_", " ") }}
            </span>
            <el-switch v-model="modules[module_id].enabled" active-color="primary"></el-switch>
          </div>

          <div style="padding: 15px">
            <el-checkbox
              v-model="modules[module_id].enabled"
              :label="module_id.toUpperCase()"
              border
            />
            <el-divider style="margin: 15px 0" />
            <div
              v-if="modules[module_id] && modules[module_id].enabled"
              style="max-height: 70vh; overflow: auto"
            >
              <div
                v-for="(setting, key) in config"
                :key="key"
                :required="setting.required"
                :id="`module-editor-${module_id}-${key}`"
                :class="scrollTo === `module-editor-${module_id}-${key}` ? 'is-highlighted' : ''"
              >
                <component
                  v-model="modules[module_id]"
                  :name="key"
                  :setting="setting"
                  :is="toComponent(setting)"
                  :modules="modules"
                ></component>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div v-else style="display: flex; height: 100%">
        <el-collapse
          style="min-width: 240px; border-right: 1px solid #dedede; height: 100%"
          v-model="activeModuleParent"
        >
          <el-collapse-item
            v-for="(moduleNames, parent) in filteredMapping"
            :key="parent"
            :name="parent"
          >
            <template #title>
              <div style="text-align: left">
                <span style="margin-right: 10px; font-size: 16px; padding: 10px; font-weight: bold">
                  {{ parent.toUpperCase().replace("_", " ") }}
                </span>
              </div>
            </template>
            <div v-for="(module_id, index) in moduleNames" :key="index">
              <div
                v-if="filteredList[module_id]"
                style="
                  font-size: 16px;
                  padding: 10px;
                  cursor: pointer;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
                @click="selectedModule = module_id"
              >
                <span
                  style="
                    line-height: 9px;
                    vertical-align: middle;
                    margin-right: 10px;
                    font-weight: 400;
                    white-space: nowrap;
                    box-sizing: border-box;
                  "
                  :style="{
                    color: module_id === selectedModule ? '#1d57d8' : '#000',
                    fontWeight: module_id === selectedModule ? 'bold' : 'normal',
                  }"
                  >{{ module_id.toUpperCase().replace("_", " ") }}</span
                >
                <el-switch v-model="modules[module_id].enabled" active-color="#1d57d8"></el-switch>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>

        <div
          v-if="selectedModule.length > 0 && modules[selectedModule]"
          style="padding: 15px; width: 100%; overflow-y: hidden"
        >
          <div style="padding: 15px; width: 100%">
            <el-checkbox
              v-model="modules[selectedModule].enabled"
              :label="selectedModule.toUpperCase()"
              border
            />
            <el-divider style="margin: 15px 0" />
            <div
              v-if="modules[selectedModule] && modules[selectedModule].enabled"
              style="max-height: 70vh; overflow: auto"
            >
              <div
                v-for="(setting, key) in filteredList[selectedModule]"
                :key="key"
                :required="setting.required"
                :id="`module-editor-${selectedModule}-${key}`"
                :class="
                  scrollTo === `module-editor-${selectedModule}-${key}` ? 'is-highlighted' : ''
                "
              >
                <component
                  v-model="modules[selectedModule]"
                  :name="key"
                  :setting="setting"
                  :is="toComponent(setting)"
                  :modules="modules"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>

      <JSONEditor
        v-if="$store.state.showAdvanced"
        v-model="modules"
        @valid="valid = true"
        @invalid="valid = false"
      />
    </el-main>

    <!-- setting change cofirm popup -->
    <el-dialog title="Confirmation" :visible.sync="confirmDialog">
      <div v-if="havingChanges" v-html="getChanges()" class="changes-wrapper"></div>
      <div
        v-else
        class="changes-wrapper"
        style="place-content: center; place-items: center; display: grid"
      >
        No change has been made to the Settings
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="confirmDialog = false">Cancel</el-button>
        <el-button size="mini" type="primary" v-if="havingChanges" @click="saveChanges"
          >Save</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>
<script>
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";
import _, { filter } from "lodash";
import { tabSettingValidation } from "@/helperMethods/modules";

// Components
import JSONEditor from "@/components/JSONEditor";
import SmartFieldString from "./SmartFields/String";
import SmartFieldList from "./SmartFields/List.vue";
import SmartFieldNumber from "./SmartFields/Number";
import SmartFieldAutocomplete from "./SmartFields/Autocomplete";
import SmartFieldObject from "./SmartFields/Object";
import SmartFieldTimerange from "./SmartFields/Timerange";
import SmartFieldDaterange from "./SmartFields/Daterange";
import SmartFieldDate from "./SmartFields/Date";
import SmartFieldTag from "./SmartFields/Tag";
import SmartFieldBoolean from "./SmartFields/Boolean";
import SmartFieldColor from "./SmartFields/Color";
import SmartFieldSelect from "./SmartFields/Select";
import SmartFieldMultiselect from "./SmartFields/Multiselect";
import SmartFieldTextarea from "./SmartFields/Textarea";
import SmartFieldJson from "./SmartFields/Json";
import SmartFieldYaml from "./SmartFields/Yaml";
import SmartFieldButton from "./SmartFields/Button";
import SmartFieldTransfer from "./SmartFields/Transfer";
import SmartFieldCascader from "./SmartFields/Cascader";
import SmartFieldCascaderwithheaders from "./SmartFields/CascaderWithHeaders";
import SmartFieldDatetime from "./SmartFields/DateTime";
// import SmartFieldHandoverroutingskillslibrary from "./SmartFields/Custom/HandoverRoutingSkillsLibrary";
import SmartFieldInactivitymanager from "./SmartFields/Custom/InactivityManager/Index";
import SmartFieldFacebookadditionalcontenteditor from "./SmartFields/Custom/FacebookAdditionalContentEditor/Index";
import SmartFieldLivechathelperactions from "./SmartFields/Custom/LiveChatHelperActions/Index";
import SmartFieldAssignmentqueueactions from "./SmartFields/Custom/AssignmentQueueActions/Index";
import SmartFieldStickymenulanguage from "./SmartFields/Custom/StickyMenuLanguage";
import SmartFieldAllowuploadwheninlivechat from "./SmartFields/Custom/AllowUploadWhenInLiveChat";
import SmartFieldSingpasscertificate from "./SmartFields/Custom/SingpassCertificate";
import SmartFieldCiscoentrypointeditor from "./SmartFields/Custom/CiscoEntrypointEditor";
import SmartFieldBotredirect from "./SmartFields/Custom/BotRedirect.vue";
import SmartFieldHandoveragentchatlimitperuser from "./SmartFields/Custom/HandoverAgentChatLimitPerUser.vue";
import SmartFieldGenaitagcreate from "./SmartFields/Custom/GenAITagCreate.vue";

import Webchat from "./BaseObjects/Webchat.json";
import Facebook from "./BaseObjects/Facebook.json";
import Telegram from "./BaseObjects/Telegram.json";
import Line from "./BaseObjects/Line.json";
import Wechat from "./BaseObjects/Wechat.json";
import Twitter from "./BaseObjects/Twitter.json";
import Uccx from "./BaseObjects/Uccx.json";
import Teams from "./BaseObjects/Teams.json";
import Mobidesk from "./BaseObjects/Mobidesk.json";
import Twilio from "./BaseObjects/Twilio.json";
import System from "./BaseObjects/System.json";
import Evaluation from "./BaseObjects/Evaluation.json";
import Analytics from "./BaseObjects/Analytics.json";
import FlowEditor from "./BaseObjects/FlowEditor.json";
import Pii from "./BaseObjects/Pii.json";
import Ameyo from "./BaseObjects/Ameyo.json";
import Article from "./BaseObjects/Article.json";
import Subscription from "./BaseObjects/Subscription.json";
import Broadcast from "./BaseObjects/Broadcast.json";
import Infermedica from "./BaseObjects/Infermedica.json";
import GoogleMaps from "./BaseObjects/GoogleMaps.json";
import Search from "./BaseObjects/Search.json";
import Voice from "./BaseObjects/Voice.json";
import Email from "./BaseObjects/Email.json";
import Workplace from "./BaseObjects/Workplace.json";
import Rating from "./BaseObjects/Rating.json";
import MakerChecker from "./BaseObjects/MakerChecker.json";
import Alerts from "./BaseObjects/Alerts.json";
import Visitors from "./BaseObjects/Visitors.json";
import PretrainedSkills from "./BaseObjects/PretrainedSkills.json";
import Whatsapp from "./BaseObjects/Whatsapp.json";
import Prism from "./BaseObjects/Prism.json";
import WebPageEditor from "./BaseObjects/WebpageEditor.json";
import Viber from "./BaseObjects/Viber.json";
import CiscoECE from "./BaseObjects/CiscoECE.json";
import Genesys from "./BaseObjects/Genesys.json";
import Infobip from "./BaseObjects/Infobip.json";
import ImagePurge from "./BaseObjects/ImagePurge.json";
import Bookworm from "./BaseObjects/Bookworm.json";
import FilePurge from "./BaseObjects/FilePurge.json";
import Gateway from "./BaseObjects/Gateway.json";
import Form from "./BaseObjects/Form.json";
import Synthesia from "./BaseObjects/Synthesia.json";
import Singpass from "./BaseObjects/Singpass.json";
import Templates from "./BaseObjects/Templates.json";
import Developer from "./BaseObjects/Developer.json";
import Zoom from "./BaseObjects/Zoom.json";
import OutReach from "./BaseObjects/OutReach.json";
import Calendly from "./BaseObjects/Calendly.json";
import Azure from "./BaseObjects/Azure.json";
import OpenAI from "./BaseObjects/OpenAI.json";
import Audio from "./BaseObjects/Audio.json";
import Ecare from "./BaseObjects/Ecare.json";
import CustomerCare from "./BaseObjects/CustomerCare.json";
import GenAI from "./BaseObjects/GenAI.json";
import SmartClassifier from "./BaseObjects/SmartClassifier.json";

// Mixins
import languagesMixin from "@/mixins/languages.ts";
import HandoverFormMixin from "./Mixins/Handover";
import TranslateFormMixin from "./Mixins/Translate";
import ZendeskFormMixin from "./Mixins/Zendesk";
import FallbackFormMixin from "./Mixins/Fallback";
import CoreFormMixin from "./Mixins/Core";
import FaqFormMixin from "./Mixins/Faq";
import CentionFormMixin from "./Mixins/Cention";
import ModuleEditorUtil from "./util";
import { updateUrlQuery } from "@/helperMethods/util";
import modulesMapping from "./helpers/mapping.json";

export default {
  components: {
    JSONEditor,
    SmartFieldTimerange,
    SmartFieldDaterange,
    SmartFieldDate,
    SmartFieldList,
    SmartFieldString,
    SmartFieldNumber,
    SmartFieldAutocomplete,
    SmartFieldObject,
    SmartFieldTag,
    SmartFieldBoolean,
    SmartFieldColor,
    SmartFieldSelect,
    SmartFieldMultiselect,
    SmartFieldTextarea,
    SmartFieldJson,
    SmartFieldYaml,
    SmartFieldButton,
    SmartFieldTransfer,
    SmartFieldCascader,
    SmartFieldCascaderwithheaders,
    SmartFieldDatetime,
    // SmartFieldHandoverroutingskillslibrary,
    SmartFieldInactivitymanager,
    SmartFieldFacebookadditionalcontenteditor,
    SmartFieldLivechathelperactions,
    SmartFieldAssignmentqueueactions,
    SmartFieldStickymenulanguage,
    SmartFieldGenaitagcreate,
    SmartFieldSingpasscertificate,
    SmartFieldCiscoentrypointeditor,
    SmartFieldBotredirect,
    SmartFieldHandoveragentchatlimitperuser,
    SmartFieldAllowuploadwheninlivechat,
  },
  mixins: [
    languagesMixin,
    HandoverFormMixin,
    TranslateFormMixin,
    ZendeskFormMixin,
    FallbackFormMixin,
    CoreFormMixin,
    FaqFormMixin,
    CentionFormMixin,
  ],
  data() {
    return {
      saving: false,
      valid: true,
      query: "",
      tabPosition: window.innerWidth <= 768 ? "top" : "left",
      departments: [],
      active: _.get(this.$route, "query.active"),
      confirmDialog: false,
      originalModules: {},
      modulesMapping,
      selectedModule: "",
      isMobileDevice: this.$device.Mobile,
      activeModuleParent: [],
    };
  },
  computed: {
    scrollTo() {
      return _.get(this.$route, "query.scroll_to", "");
    },
    contentNodeList() {
      return Object.keys(this.contentNodes).map((contentName) => {
        return {
          label: contentName,
          value: contentName,
        };
      });
    },
    form() {
      const apis = _.chain(this)
        .get("$store.state.apieditor.mappings", [])
        .map((mapping, mappingName) => ({
          key: mappingName,
          value: mappingName,
        }))
        .value();

      return {
        //Out reach
        outreach: OutReach,
        // templates
        templates: Templates,
        // Channels
        system: System,
        webchat: Webchat,
        audio: Audio,
        facebook: Facebook,
        telegram: Telegram,
        line: Line,
        wechat: Wechat,
        whatsapp: Whatsapp,
        zendesk: this.zendeskFormMixin,
        twitter: Twitter,
        cention: this.centionFormMixin,
        uccx: Uccx,
        teams: Teams,
        moobidesk: Mobidesk,
        twilio: Twilio,
        faq: this.faqFormMixin,
        viber: Viber,
        infobip: Infobip,
        ciscoEce: CiscoECE,
        // Core System
        evaluation: Evaluation,
        genesys: Genesys,
        access_control: {},
        analytics: Analytics,
        floweditor: FlowEditor,
        webpage_editor: WebPageEditor,
        pii: Pii,
        ameyo: Ameyo,
        imagePurge: ImagePurge,
        filePurge: FilePurge,
        // Escalation
        handover: this.handoverFormMixin,
        article: Article,
        subscription: Subscription,
        broadcast: Broadcast,
        core: this.coreFormMixin,
        infermedica: Infermedica,
        fallback: this.fallbackFormMixin(apis),
        google_maps: GoogleMaps,
        search: Search,
        voice: Voice,
        email: Email,
        translate: this.translateFormMixin,
        workplace: Workplace,
        rating: Rating,
        makerChecker: MakerChecker,
        alerts: Alerts,
        visitors: Visitors,
        bookworm: Bookworm,
        prism: Prism,
        pretrained_skills: PretrainedSkills,
        menu: true,
        tnc: false,
        gateway: Gateway,
        form: Form,
        synthesia: Synthesia,
        singpass: Singpass,
        developer: Developer,
        zoom: Zoom,
        calendly: Calendly,
        azure: Azure,
        openai: OpenAI,
        ecare: Ecare,
        customer_care: CustomerCare,
        genai: GenAI,
        smartclassifier: SmartClassifier,
      };
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    filteredList() {
      const filteredList = ModuleEditorUtil.getFilteredList(
        this.form,
        this.$store.state.modules,
        this.query
      );

      return _.chain(filteredList).toPairs().sortBy(0).fromPairs().value();
    },
    contentNodes() {
      return this.$store.state.nodes.content;
    },
    havingChanges() {
      return !_.isEqual(this.originalModules, this.modules);
    },
    filteredMapping() {
      const filteredMapping = _.chain(this.modulesMapping)
        .mapValues((moduleNames) => {
          return moduleNames.filter((moduleName) => this.filteredList[moduleName]);
        })
        .pickBy((moduleNames) => moduleNames.length > 0)
        .value();

      if (this.query.length > 0) {
        this.activeModuleParent = Object.keys(filteredMapping)[0];
      }
      return filteredMapping;
    },
  },
  watch: {
    active(newVal, oldVal) {
      if (newVal !== oldVal) {
        updateUrlQuery({
          active: newVal,
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("FETCH_AGENT_DEPARTMENTS").then((departments) => {
      this.departments = departments;
    });

    setTimeout(() => {
      const scrollTo = _.get(this.$route, "query.scroll_to");
      if (scrollTo) {
        const element = document.getElementById(scrollTo);
        if (element) {
          element.scrollIntoView();
        }
      }
    }, 500);

    this.saveOriginalModules();
  },
  created() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    saveOriginalModules() {
      this.originalModules = _.cloneDeep(this.modules);
    },
    getContentNodeSuggestions(queryString, cb) {
      const contentNodeNames = Object.keys(this.contentNodes);
      const filteredContentNodeNames = _.chain(contentNodeNames)
        .filter((contentName) => contentName.includes(queryString))
        .map((contentName) => {
          return { value: contentName };
        })
        .value();
      cb(filteredContentNodeNames);
    },
    toComponent(setting) {
      const type = _.capitalize(setting.type);
      const componentName = `SmartField${type}`;
      const isComponentExist = _.has(this.$options.components, componentName);
      return isComponentExist ? componentName : null;
    },
    resize() {
      this.tabPosition = window.innerWidth <= 768 ? "top" : "left";
    },
    search(query, cb) {
      let suggestions = Object.keys(this.form)
        .filter((name) => name.includes(this.query))
        .map((name) => ({ value: name }));
      cb(suggestions);
    },
    getChanges() {
      jsondiffpatch.formatters.html.hideUnchanged();

      const delta = jsondiffpatch.diff(this.originalModules, this.modules);
      const changes = jsondiffpatch.formatters.html.format(delta, this.originalModules);
      return changes;
    },
    reviewChanges(event) {
      this.confirmDialog = true;
    },
    saveChanges(event) {
      this.confirmDialog = false;
      console.log("modules:", this.modules);
      const tabSetting = _.get(this.modules, "webchat.tabs", []);
      const stickyMenuSetting = _.get(this.modules, "webchat.stickyMenuConfig", null);
      const isGenAIEnabled = _.get(this.modules, "genai.enabled", false);

      if (!tabSettingValidation(tabSetting)) {
        this.$message({
          type: "error",
          message: "Error saving tab config",
        });
        return;
      }

      if (stickyMenuSetting) {
        let hasEmptyValue = false;

        stickyMenuSetting?.stickyMenuLanguages.forEach((item) => {
          if (_.isEmpty(item.lang) || _.isEmpty(item.value)) hasEmptyValue = true;
        });

        const listLanguage = stickyMenuSetting?.stickyMenuLanguages.map((item) => item.lang);

        if (hasEmptyValue || _.uniq(listLanguage).length !== listLanguage.length) {
          this.saving = false;
          this.$message({
            type: "error",
            message: "Error: Sticky Menu setting has empty or duplicate value",
          });
          return;
        }
      }

      if (isGenAIEnabled) {
        const tags = _.get(this.modules, "genai.knowledge_document.tags", []);
        const tagNameArr = tags.map((tag) => tag.name);
        const isDuplicatedTagName = _.uniq(tagNameArr).length !== tagNameArr.length;
        if (isDuplicatedTagName) {
          this.$message({
            type: "error",
            message: "Error Saving: GenAI module has a duplicated tag's category name.",
          });
          return;
        }
      }

      this.saving = true;
      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Config Saved",
            });
            // sync up two variables after saving
            this.saveOriginalModules();
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
    handleQueryChange(query) {
      this.activeModuleParent = Object.keys(this.filteredMapping)[0];
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";
@import "~jsondiffpatch/dist/formatters-styles/html.css";

.is-highlighted,
.is-highlighted .el-card,
.is-highlighted .el-card .el-table {
  background-color: lighten($color-primary, 45);
}

.changes-wrapper {
  margin: 20px;
  padding: 10px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: #fdfdfd;
  border-radius: 4px;
  overflow: auto;
}
</style>
